<template>
    <div class="box-editor">
        <!-- 文档的基本信息 -->
        <el-form ref="form" label-position="left" :model="form" label-width="100px">
            <el-form-item label="文章标题" required>
                <el-input v-model="form.title" placeholder="20字符以内"></el-input>
            </el-form-item>
            <el-form-item>
                <!-- editor -->
                <vue-ueditor-wrap v-model="form.content" :config="myConfig"></vue-ueditor-wrap>
            </el-form-item>
            <el-form-item label="保存到专栏">
                 <el-select v-model="form.name" @change="refreshDocId(1)"
                  filterable placeholder="--选择专栏--">
                    <el-option
                    v-for="item in docList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="form.chapter" @change="refreshDocId(2)"
                  filterable placeholder="--选择章--">
                    <el-option
                    v-for="item in currentChapters"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="form.section"
                  filterable placeholder="--选择节--">
                    <el-option
                    v-for="item in currentSections"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <el-button icon="el-icon-plus" circle @click="addName"></el-button>
            </el-form-item>
            <el-form-item label="展示封面">
                    <el-upload 
                    :file-list="fileList"
                    :on-success="uploadSuccess"
                    :on-error="uploadFail"
                    :auto-upload="true"
                    :limit="3"
                    :data="formData"
                    :action="myConfig.serverUrl"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="save">提交</el-button>
            </el-form-item>
        </el-form>
        <!-- 弹出层 -->
         <!-- 添加资质 -->
        <el-dialog :title="addTitle" 
        :visible.sync="drawer">
            <AddDoc :inputType="inputType" @addInput="addDocName"/>
        </el-dialog>
        
    </div>
</template>
<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import AddDoc from '@/components/AddDoc'
export default {
    components: {
        VueUeditorWrap,
        AddDoc
    },
    data(){
        return{
            updateId:null,//要更新文档的id
            currentSections:[],//根据章变化获取的节列表
            sections:[],//节列表
            addTitle:"添加专栏",
            inputType:"",//添加章节/专栏类型
            drawer:false,
            fileList:[],//文件列表
            formData:{
                method:'uploadImg'
            },
            actionUrl:'',
            dialogImageUrl: '',
            dialogVisible: false,
            docList:[],//文档列表
            chapters:[],//章节名称
            currentChapters:[],//根据专栏变化获取相应的章节
            form:{
                name:'',
                chapter:'',
                section:'',
                content:'',
                title:''
            },
            options: [],
            user:JSON.parse(sessionStorage.getItem('user')),
             myConfig: {
                // 编辑器不自动被内容撑高
                serverparam:{
                    method:"ueditorUpload"
                },
                autoHeightEnabled: false,
                // 初始容器高度
                initialFrameHeight: 240,
                // 初始容器宽度
                initialFrameWidth: '100%',
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
                serverUrl: "",
                baseUrl:"",
                imageActionName:"",
                // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
                UEDITOR_HOME_URL: '/UEditor/'
            }
        }
    },
    watch:{
         $route( to ){
            //console.log( to , from )
            //  to , from 分别表示从哪跳转到哪，都是一个对象
            // to.path   ( 表示的是要跳转到的路由的地址 eg:  /home );
            // to.query.id 提取id进行http请求数据更新页面
            if(to.query.id){
                //获取要更新的文档数据
                //console.log(to.query.id,"id")
                this.updateId = to.query.id 
                if(to.query.nid&&to.query.nid!="0"){
                    this.form.name = to.query.nid
                }
                if(to.query.sid&&to.query.sid!="0"){
                    this.form.section = to.query.sid
                }
                if(to.query.cid&&to.query.cid!="0"){
                    this.form.chapter = to.query.cid 
                }
                this.getNameList(true)
            }
        }
    },
    created(){
        this.myConfig.serverUrl=this.API.api+'?token='+this.user.token
        this.myConfig.baseUrl=this.API.api 
        //获取专栏列表
        if(this.$route.query.id){//穿了文档参数过来，则获取文档详情
            let to = this.$route
            this.updateId = to.query.id 
                if(to.query.nid&&to.query.nid!="0"){
                    this.form.name = to.query.nid
                }
                if(to.query.sid&&to.query.sid!="0"){
                    this.form.section = to.query.sid
                }
                if(to.query.cid&&to.query.cid!="0"){
                    this.form.chapter = to.query.cid 
                }
                this.getNameList(true)
        }else{
            this.getNameList()
        }
    },
    methods:{
        getUpdate(){
             let params = {
                method:'getDocument',
                id:this.updateId,
                nid:this.form.name,
                Loading:true
            }
            this.currentChapters = this.chapters 
            this.currentSections = this.sections
           // console.log(params,this.API.api)
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log("getUpdate",res)
                let data = res.data.data[0] 
                this.form.title = data.title
                this.form.content = data.content 
                document.title = "更新文档-"+data.title
                //console.log(this.form)
            }).catch(err=>{
                //err
                this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
            })
        },
        uploadFail(err){
            console.log("上传失败",err)
            this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
        },
        //选择文档名称
        selectChapter(item){
            console.log(item)
        }
        ,//保存内容
        save(){
            if(this.form.title==""){
                this.$message({
                    message: "标题"+this.CONST.LENGTH_2_20,
                    type: 'warning'
                });
                return
            }
            if(this.form.content.length<=0){
                this.$message({
                    message: this.CONST.REQUIRED_CONTENT,
                    type: 'warning'
                });
                return
            }
            //是否有专栏未选择章
            if(this.form.name!=""&&!this.form.chapter){
                this.$message({
                    message: "标题"+this.CONST.REQUIRED_CHAPTER,
                    type: 'warning'
                });
                return
            }
            if(this.fileList.length>=1){
                this.form.cover = JSON.stringify(this.fileList)
            }
            let params = Object.assign({},this.form)
            params.method = "addDocArticle"
            params.Loading = true
            params.username = this.user.username 
            params.content = encodeURIComponent(params.content) //转译字符串空格防止数据丢失
            if(this.updateId&&this.updateId!=""){
                params.updateId = this.updateId
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //res
                console.log(res)
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                //清空数据
                this.$refs['form'].resetFields();
            }).catch(err=>{
                //err
                console.log(err)
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
            
        },
        //移除图片
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        //预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        }
        //上传图片成功
        ,uploadSuccess(response){
            //console.log("上传成功",response,fileList)
            console.log(this.API.api+response.data)
            if(response.status==0){
                this.fileList.push({
                    name:response.data,
                    url:this.API.api+response.data
                })
            }
        }
        ,//动态更新对应的章节
        refreshDocId(status){
            this.currentChapters=[],this.currentSections=[]
            if(status==1){//更改了专栏，清空后面的章节数据
                this.form.chapter = ''
                this.form.section = ''
            }else if(status==2){
                this.form.section = ''
            }
            //console.log('改变更新',this.form.name)
            if(this.form.name&&this.form.name!=""){
                let currentCp = [],name = this.form.name
                this.chapters.forEach(function(item){
                    console.log(item.nid,name)
                    if(item.nid==name){
                        currentCp.push(item)
                    }
                })
                //console.log('cu',currentCp)
                this.currentChapters = currentCp
                //  更新章
                if(this.form.chapter&&this.form.chapter!=""){
                    let currentSec = [],chapter = this.form.chapter
                    this.sections.forEach(function(item){
                        console.log(item.cid,chapter)
                        if(item.cid==chapter){
                            currentSec.push(item)
                        }
                    })
                    this.currentSections = currentSec
                }
            }
        }
        //添加专栏/章节
        ,addName(){
            this.drawer = true 
            if(this.form.name==""){//若是没有专栏，则弹出添加
                this.inputType = "addDocName" 
                this.addTitle = "添加专栏"
            }else if(this.form.name!=""&&this.form.chapter==""){
                this.inputType = "addDocChapter"
                this.addTitle = "添加章"
            }else {
                this.inputType = "addDocSection"
                this.addTitle = "添加节"
            }
            
        },
        //获取专栏名称列表
        getNameList(update){
            let params = {
                method:"getDocNameList"
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
                let nameList = [],chapterList = [],sections=[]
                res.data.doc_name.forEach(function(item){
                    nameList.push({
                        value:item.id,
                        label:item.title
                    })
                })
                res.data.doc_chapter.forEach(function(item){
                    chapterList.push({
                        value:item.id,
                        label:item.title,
                        nid:item.nid
                    })
                })
                res.data.doc_section.forEach(function(item){
                    sections.push({
                        value:item.id,
                        label:item.title,
                        cid:item.cid
                    })
                })
                this.docList = nameList 
                this.chapters = chapterList 
                this.sections = sections
                //更新文档则获取更新的
                if(update){
                    this.getUpdate()
                }
            }).catch(err=>{
                //err
                console.log(err)
            })
        },
        //添加专栏
        addDocName(title){
            let params = {
               method:this.inputType,
               Loading:true,
               title:title,
           }
           //console.log(this.inputType)
           switch(this.inputType){
               case "addDocChapter"://其他参数
                   params.nid = this.form.name
                   break;
               case "addDocSection":
                   params.nid = this.form.name
                   params.cid = this.form.chapter
                   break;
           }
           this.drawer = false
           //if(this.inputType=="addDocChapter")
           //console.log(params,this.form)
           this.$ajax.post(this.API.api,params).then(res=>{
               //todo
               //console.log(res)
               if(res.status==0){
                   this.$message({
                        message: this.CONST.SUC_ADD,
                        type: 'success'
                    });
                    this.getNameList()
               }
           }).catch(err=>{
               //err
               console.log(err)
               this.$message({
                    message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                    type: 'warning'
                });
           })
        }
    }
}
</script>
<style lang="less">
.el-form-item__content{
    line-height: normal;
    .el-select{
        margin-right: 10px;
    }
}
.el-button.is-circle{
    margin-left:10px
}

</style>