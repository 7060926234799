<template>
    <div class="chapter">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="专栏名称" v-if="inputType=='addDocName'">
                <el-autocomplete
                class="inline-input"
                v-model="form.title"
                :fetch-suggestions="querySearch"
                :placeholder="this.CONST.ADD_CHAPTER_BTN"
                @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item label="章 - 标题" v-if="inputType=='addDocChapter'">
                <el-autocomplete
                class="inline-input"
                v-model="form.title"
                :fetch-suggestions="querySearch"
                :placeholder="this.CONST.ADD_CHAPTER_BTN"
                @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item label="节 - 标题"  v-if="inputType=='addDocSection'">
                <el-autocomplete
                class="inline-input"
                v-model="form.title"
                :fetch-suggestions="querySearch"
                :placeholder="this.CONST.ADD_CHAPTER_BTN"
                @select="handleSelect"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="save">提交</el-button>
            </el-form-item>
        </el-form>
        
    </div>
</template>
<script>
export default {
    props:{
        inputType:{
            type:String,
            value:'addDocName'
        },
    },
    data(){
        return{
            placeholder:"新建文档名称",
            placeholder2:"输入章名称",
            placeholder3:"输入节名称",
            form:{
            },
            docNameList:[]
        }
    },
    created(){
        this.getNameList()
    },
    methods:{
        //获取专栏名称列表
        getNameList(){
            let params = {
                method:"getDocNameList"
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
            }).catch(err=>{
                //err
                console.log(err)
            })
        },
        querySearch(queryString, cb) {
            var docNameList = this.docNameList;
            var results = queryString ? docNameList.filter(this.createFilter(queryString)) : docNameList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (docNameList) => {
            return (docNameList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        handleSelect(item) {
            console.log(item);
        },
        //保存
        save(){
            //console.log(this.form)
            if(!this.form.title||this.form.title==""){
                this.$message({
                    message: this.CONST.FAIL_LOGIN_PARAMS,
                    type: 'warning'
                });
                return;
            }
            this.$emit("addInput",this.form.title)
            this.form.form = ""
            
        }
    }
    
}
</script>